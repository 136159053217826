.selection{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-background {
  background: #282828;
}
