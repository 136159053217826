.footer-1 {
    position: relative;
    min-height: 5vh;
    align-items: center;
    justify-content: center;
    background-color: orange;
    text-align: center;
}

.footer-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}