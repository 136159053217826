.intro{
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.name{
    font-size: 70px;
}

.color-text-name{
    color : orange;
}

p {
    margin-bottom: 4px;
    line-height: 1.3;
}

.avatar{
    position: relative; 
    width: 100%;
    height: auto;
    z-index: 2;
    filter: grayscale(30%);
}

.avatar-loading {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid orange;
    border-bottom: 16px solid orange;
    width: 120px;
    height: 120px;
    margin: 31%;
    margin-left: 45%;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.avatar-relative{
    position: relative; 
}

.back-avatar{
    position: absolute;
    width: 100%;
    height: auto;
    right: 0;
    margin: 0 auto;
    padding: 5px 10px;
    white-space: nowrap;
    z-index: 1;
}

.sosial-media{
    font-size: 30px;
    color: white;
    margin: 10px;
}
  
.sosial-media:hover{
    color: orange;
    transition: 0.5s;
}

.musik-media{
    position: absolute;
    font-size: 30px;
    color: white;
    right: 32%;
    top: 8%;
    z-index: 3;
    animation: msk 1.5s infinite;
}
.margin-musik{

    margin-left: 85%;

    margin-bottom: 6%;

}

.musik-media:hover{
    font-size: 35px;
    color: white;
    transition: 0.5s;
    animation: msk-off 0.5s infinite;
}

.position-sosial-media{
    display: flex;
    justify-content: flex-start;
}

@media only screen and (max-width: 1200px) {
    .name{
        font-size: 40px;
    }
}

@media only screen and (max-width: 600px) {

    .avatar-loading {
        width: 120px;
        height: 120px;
        margin-left: 0;
        margin-top: 100%;
        margin-bottom: 50%;
      }
      
    
    .position-sosial-media{
        display: flex;
        justify-content: center;
    }

    .name{
        font-size: 30px;
    }
    
    p {
        margin-bottom: 4px;
        line-height: 1.3;
    }
    
    .avatar{
        position: relative; 
        width: 100%;
        height: auto; 
        z-index: 2;
        filter: grayscale(30%);
    }
    
    .back-avatar{
        position: absolute;
        top: auto;
        left: 0;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        padding: 5px 10px;
        white-space: nowrap;
        z-index: 1;
    }

    .margin-musik{

        margin-left: 80%;
    
        margin-bottom: 20%;
    
    }
}

.avatar-transisi{
    animation: avt 2s alternate;
}
  
@keyframes avt {
    0% {opacity: 0;}
  
    100% {opacity: 1;}
}

@keyframes msk {
    0% {transform: rotate(0deg);}

    50% {transform: rotate(40deg);}
  
    100% {transform: rotate(0deg);}
}

@keyframes msk-off {
    0% {transform: rotate(0deg);}

    50% {transform: rotate(0deg);}
  
    100% {transform: rotate(0deg);}
}


  