.work-text{
    color: #282828 !important;
}

.work-experience-text {
    transition-duration: 0.5s;
    text-decoration: underline solid orange 10%;
    text-underline-offset: 15px;
    text-align: center;
}

.work-item {
    overflow: visible;
    animation: opacity 1s alternate;
}

@keyframes opacity {
    0% {
        margin-left: 40%;
        opacity: 0;
    }

    100% {
        margin-left: 0px;
        opacity: 1;
    }
}