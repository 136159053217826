.skill-text{
    transition-duration: 0.5s;
    text-decoration: underline solid orange 10%;
    text-underline-offset: 15px;
}
  
.skill{
    box-shadow: 3px 3px #7a7a7a;
    transition-duration: 0.2s;
}
  
.skill:hover{
    box-shadow: 5px 5px orange;
    bottom: 10px
}

.skill-scroll{
    overflow: visible;
    animation: skillanim 1s alternate;
}

@media only screen and (max-width: 600px) {

    .skill-text-normal{
        text-decoration: underline solid orange 10%;
        text-underline-offset: 15px;
    }

    .skill-text{
        text-align: center;
        transition-duration: 0.5s;
        text-decoration: underline solid orange 10%;
        text-underline-offset: 15px;
    }
}
  
@keyframes skillanim {
    0% {
        margin-left: 0%;
        margin-right: 30%;
        opacity: 0;
    }

    100% {
        margin-left: 0px;
        margin-right: 0px;
        opacity: 1;
    }
}

