.project-text {
    transition-duration: 0.5s;
    text-decoration: underline solid orange 10%;
    text-underline-offset: 15px;
    text-align: center;
}

.project-images {
    max-width: 100%;
    padding: 10px;
    box-shadow: 0px 0px orange;
    border: 3px solid white;
    box-sizing: border-box;
    transition-duration: 0.2s;
}

.project-images-loading {
    padding: 48%;
    background: linear-gradient(to top, orange, #282828);
    box-shadow: 0px 0px orange;
    border: 3px solid white;
    box-sizing: border-box;
    animation: shimmer 1s infinite;
}

@keyframes shimmer {
    0% {
      filter: brightness(50%);
    }
    50% {
      filter: brightness(100%);
    }
    100% {
      filter: brightness(50%);
    }
  }

.project-container {
    position: relative;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 2;
    right: 2;
    height: 0;
    width: 93%;
    overflow: hidden;
    transition: .5s ease;
    
}

.project-container:hover .overlay {
    height: 100%;
}

.text {
    background-color: orange;
    text-align: center;
    color: white;
    font-size: 20px;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    border: 2px solid #282828;
    padding: 3px 10px 3px 10px;
    border-radius: 5px;
    color: #282828;
}

.project-container:hover .project-images{
    max-width: 100%;
    padding: 10px;
    box-shadow: 5px 7px orange;
    border: 3px solid white;
    box-sizing: border-box;
    transform: scale(1.02);
}

.project-image-scroll {
    overflow: visible;
    animation: projectimganim 1s alternate;
}


@media only screen and (max-width: 600px) {

    .project-text {
        text-align: center;
        transition-duration: 0.5s;
        text-decoration: underline solid orange 10%;
        text-underline-offset: 15px;
    }
    .project-image-scroll {
        margin: auto;
        width: 70%;
        height: auto;
        animation: projectimganim-small 1s alternate;
    }
}

@keyframes projectimganim {
    0% {
        margin-left: 40%;
        opacity: 0;
    }

    100% {
        margin-left: 0px;
        opacity: 1;
    }
}

@keyframes projectimganim-small {
    0% {
        margin-left: 40%;
        opacity: 0;
    }

    100% {
        margin-left: 15%;
        opacity: 1;
    }
}