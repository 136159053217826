.all-project-text {
  text-align: center;
  text-decoration: underline solid orange 10%;
  text-underline-offset: 15px;
  margin-top: 7%;
  animation: txt-projrct 1.5s alternate;
  text-align: center;
}

.all-image {
  animation: all-image 1.5s alternate;
  align-items: center;
}

@keyframes txt-projrct {
  0% {
    opacity: 0;
    margin-top: 20%;
  }

  100% {
    opacity: 1;
  }
}

@keyframes all-image {
  0% {
    opacity: 0;
    margin-top: 20%;
  }

  100% {
    opacity: 1;
    margin-top: 0%;
  }
}

@media only screen and (max-width: 600px) {
  .all-project-text {
    text-align: center;
    text-decoration: underline solid orange 10%;
    text-underline-offset: 15px;
    margin-top: 18%;
    animation: txt-projrct 1.5s alternate;
  }

  .all-image {
    margin: auto;
    width: 80%;
    height: auto;
  }
}