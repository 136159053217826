.detail-project-name {
    transition-duration: 0.5s;
    text-decoration: underline solid orange 10%;
    text-underline-offset: 15px;
    margin-top: 4%;
    text-align: center;
    animation: name-projrct 1.5s alternate;
}

.detail-project-images {
    max-width: 80%;
    padding: 10px;
    margin-top: 5%;
    box-shadow: 5px 7px orange;
    border: 3px solid white;
    box-sizing: border-box;
    transition: 1;
    transition-duration: 1s;
    animation: img-projrct 1.5s alternate;
}

.detail-project-images-loading {
  padding-top: 20%;
  padding-bottom: 20%;
  width: 80%;
  background: linear-gradient(to top, orange, #282828);
  margin-top: 5%;
  border: 3px solid white;
  box-sizing: border-box;
  animation: shimmer 1s infinite;
}

.full-konten{
  width: 100%;
  height: 100%;
  overflow: scroll;
}

@keyframes shimmer {
  0% {
    filter: brightness(50%);
  }
  50% {
    filter: brightness(100%);
  }
  100% {
    filter: brightness(50%);
  }
}

@keyframes img-projrct {
    0% {
      opacity: 0;
      max-width: 75%;
    }
  
    100% {
      opacity: 1;
      max-width: 80%;
    }
}

@keyframes name-projrct {
    0% {
      opacity: 0;
      margin-top: 10%;
    }
  
    100% {
      opacity: 1;
    }
}

.des {
    margin: 3%;
    text-align: justify;
    font-size: 30px;
    animation: name-projrct 1.5s alternate;
}

.detail-project-des {
    margin: 3%;
    margin-top: -1%;
    text-align: justify;
    font-size: 22px;
    animation: name-projrct 1.5s alternate;
}

@media only screen and (max-width: 600px) {

    .detail-project-images {
        max-width: 80%;
        padding: 10px;
        margin-top: 20%;
        box-shadow: 5px 7px orange;
        border: 3px solid white;
        box-sizing: border-box;
        animation: img-projrct-2 1s alternate;
    }

    .detail-project-images-loading {
      padding-top: 20%;
      padding-bottom: 20%;
      width: 80%;
      background: linear-gradient(to top, orange, #282828);
      margin-top: 20%;
      border: 3px solid white;
      box-sizing: border-box;
      animation: shimmer 1s infinite;
    }

    .detail-project-name {
        transition-duration: 0.5s;
        text-decoration: underline solid orange 10%;
        text-underline-offset: 15px;
        margin-top: 8%;
        text-align: center;
        animation: name-projrct-2 1s alternate;
    }

    .des {
        margin: 5%;
        text-align: justify;
        font-size: 22px;
        animation: name-projrct-2 1s alternate;
    }
    
    .detail-project-des {
        margin: 5%;
        margin-top: -1%;
        text-align: justify;
        font-size: 18px;
        animation: name-projrct-2 1s alternate;
    }
}

@keyframes name-projrct-2 {
    0% {
      opacity: 0;
      margin-top: 100%;
    }
  
    100% {
      opacity: 1;
    }
}

@keyframes img-projrct-2 {
    0% {
      opacity: 0;
      max-width: 40%;
    }
  
    100% {
      opacity: 1;
      max-width: 80%;
    }
}

