.btn-outline-warning:hover {
    background: orange !important;
}

.btn-outline-warning {
    border-color: orange !important;
}

.form-control {
    color: orange;
    border-width: 2px;
    border-color: orange;
    background: transparent;
    transition: 0.7s;
}

.form-control:focus {
    border-width: 2px;
    margin-left: 30px;
    margin-bottom: 30px;
    box-shadow: 4px 4px orange;
    border-color: white;
    background: orange;
}

.form-control:focus::placeholder {
    color: #282828;
}

.contact-position {
    align-items: center;
}

.contact-text {
    font-size: 30px;
    color: white;
    text-align: center;
    text-shadow: 0 0 3px orange;
}

@media only screen and (max-width: 800px) {
    .contact-position {
        align-items: center;
        text-align: center;
    }
    .contact-text {
        font-size: 20px;
        padding: 6%;
    }
}

.scroll-form{
    overflow: visible;
    animation: formanim 1s alternate;
}

.scroll-text{
    overflow: visible;
    animation: textanim 2s alternate;
}

@keyframes formanim {
    0%{
        margin-left: -50%;
        margin-right: 50%;
        opacity: 0;
    }
    100%{
        margin-left: 0%;
        margin-right: 0%;
        opacity: 1;
    }
}

@keyframes textanim{
    0%{
        margin-left: -150%;
        margin-right: 150%;
        opacity: 0;
    }
    100%{
        margin-left: 0%;
        margin-right: 0%;
        opacity: 1;
    }
}