.bg-loading {
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius:10px;
}

.in-loading {
  background-color: orange;
  animation: loading;
  animation-duration: 2s;
  position: flex;
  align-items: start;
  border-radius:10px;
}

.anim-loading{
  display: flex;
  justify-content: flex-end;
}

@keyframes loading {
  0% {
    width: 10%;
  }

  100% {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {

  @keyframes loading {
    0% {
      width: 30%;
    }
  
    100% {
      width: 100%;
    }
  }

}