.avatar-about-me {
  padding: 10px;
  box-shadow: 7px 10px orange;
  width: 300px;
  box-sizing: border-box;
  border: 3px solid white;
  margin: auto;
  transition: 1.5;
  transition-duration: 1s;
}

.avatar-about-me-scroll {
  padding: 10px;
  box-shadow: 7px 10px orange;
  width: 300px;
  box-sizing: border-box;
  border: 3px solid white;
  margin: auto;
  transition: 1.5;
  transition-duration: 1s;
  transform: scale(1.2);
  animation: avt-abme 2s alternate;
}

.text-about-me {
  font-size: 20px;
  color: white;
}

.text-about-me-scroll {
  font-size: 20px;
  color: white;
  animation: tx-abme 2s alternate;
}

@keyframes avt-abme {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes tx-abme {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.about-text{
  text-decoration: underline solid orange 10%;
  text-underline-offset: 15px;
  margin-bottom: 20px;
}

.avatar-relative{
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .avatar-about-me {
    border: 2px solid;
    padding: 10px;
    box-shadow: 7px 10px orange;
    width: 250px;
    box-sizing: border-box;
    border: 3px solid white;
    margin: auto;
    transition: 1.5;
    transition-duration: 1s;
  }
  
  .avatar-about-me-scroll {
    border: 2px solid;
    padding: 10px;
    box-shadow: 7px 10px orange;
    width: 250px;
    box-sizing: border-box;
    border: 3px solid white;
    margin: auto;
    transition: 1.5;
    transition-duration: 1s;
    transform: scale(1.2);
    animation: avt-abme 2s alternate;
  }
  
  .text-about-me {
    font-size: 15px;
    color: white;
    transition: 1.5;
  }
  
  .text-about-me-scroll {
    font-size: 15px;
    color: white;
    animation: tx-abme 2s alternate;
    transition: 1.5;
  }
}

@media only screen and (max-width: 767px) {
  .avatar-about-me {
    border: 2px solid;
    padding: 10px;
    box-shadow: 7px 10px orange;
    width: 230px;
    box-sizing: border-box;
    border: 3px solid white;
    margin: auto;
    transition: 1.5;
    transition-duration: 1s;
  }
  
  .avatar-about-me-scroll {
    border: 2px solid;
    padding: 10px;
    box-shadow: 7px 10px orange;
    width: 230px;
    box-sizing: border-box;
    border: 3px solid white;
    margin: auto;
    transition: 1.5;
    transition-duration: 1s;
    transform: scale(1.2);
    animation: avt-abme 2s alternate;
  }
  
  .text-about-me {
    font-size: 15px;
    color: white;
    transition: 1.5;
    padding: 20px;
    padding-top: 60px;
    text-align: center;
  }
  
  .text-about-me-scroll {
    font-size: 15px;
    color: white;
    animation: tx-abme 2s alternate;
    transition: 1.5;
    padding: 20px;
    padding-top: 60px;
    text-align: center;
  }
}