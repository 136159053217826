.welcome-image{
    margin: auto;
    animation: anim-welcome-image 3s alternate;
}

@keyframes anim-welcome-image {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
}

/* .welcome-text{
    text-align: center;
    color: orange;
    margin-top: 20%;
    font-size: 30px;
} */