.nav{
    background:transparent;
}
  
.nav-dark{
    background:#282828;
}

.navbar-brand:hover{
    color: orange !important;
}

.nav-link{
    color:white !important;
}

.nav-link:hover{
    color: orange !important;
}

.active{
    color:orange !important
}

.sub-musik-media{
    color: white;
    animation: msk 1.5s infinite;
}

.sub-musik-media:hover{
    font-size: 17px;
    color: white;
    transition: 0.5s;
    animation: msk-off 0.5s infinite;
}

@media only screen and (max-width: 600px) {

    .sub-musik-media{
        color: white;
        animation: msk-off 0.5s infinite;
    }

}

@keyframes msk {
    0% {transform: rotate(0deg);}

    50% {transform: rotate(40deg);}
  
    100% {transform: rotate(0deg);}
}

@keyframes msk-off {
    0% {transform: rotate(0deg);}

    50% {transform: rotate(0deg);}
  
    100% {transform: rotate(0deg);}
}
